import React, { useEffect, useState } from "react";

//css
import "../../assets/css/careerMap.css";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Grid, Typography } from "@mui/material";
import { Helmet } from "react-helmet";
import { Linguistic } from "./data";
import useTrackTime from "../../hook/useTrackTime";
import { Link, useParams } from "react-router-dom";
import { getClpDetails } from "../services/services";
import { useDispatch } from "react-redux";
import { setAlert } from "../../redux/action/action";
import { color } from "framer-motion";
export default function CareerMapping() {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const timeSpan = useTrackTime();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchDetails();
  }, [id]);

  async function fetchDetails() {
    try {
      let res = await getClpDetails(id);
      if (res.status !== 200)
        dispatch(
          setAlert({
            type: "error",
            message: "Sorry, error while fetching details.",
            open: true,
          })
        );
      setData({ ...res?.data?.data });
    } catch (error) {
      console.log(error);
      dispatch(
        setAlert({
          type: "error",
          message: "Something went wrong.",
          open: true,
        })
      );
    }
  }

  return (
    <Box sx={{ padding: "3% 7% 0% 7%" }}>
      {data && (
        <>
          {/* helmet tag  */}
          <Helmet>
              <title>ClassBazaar || Career Mapping</title>
              <meta
                name="description"
                content="Let's shape your career with ClassBazaar Career Mapping Guide."
              />
          </Helmet>
          {/* helmet tag ends  */}
          <Grid
            container
            mb={5}
            mt={5}
            className={"careerTopContainer"}
            spacing={2}
          >
            {/* sec-1 */}
            <Grid
              item
              xs={12}
              className="d-flex flex-column gap-3 text-center "
            >
              <Typography
                variant="h4"
                style={{ textAlign: "center", fontWeight: 700 }}
                className="career-heading-color"
              >
                CAREER MAPPING FOR YOUR INTELLIGENCE TYPE
              </Typography>
              <Typography
                variant="h5"
                style={{ textAlign: "center", fontWeight: 700 }}
                className="career-heading-color"
              >
                {data?.intelligence}
              </Typography>
              <Typography variant="body1" className="px-5 ">
                {data?.intelligence_des}
              </Typography>
            </Grid>
            <Grid item xs={12} className="d-flex flex-column gap-2 ">
              <Typography
                variant="h5"
                style={{ textAlign: "center", fontWeight: 700 }}
                className="career-heading-color"
              >
                DESCRIPTION OF YOUR CHOSEN CAREER:
              </Typography>
              <Typography
                variant="h2"
                style={{ textAlign: "center", fontWeight: 700 }}
                className="career-heading-color-2"
              >
                {data?.cpl_name}
              </Typography>
            </Grid>
            {/* sec-1 end*/}

            {/* sec-2 */}
            <Grid item xs={12} className="d-flex flex-column gap-3 ">
              <Box className="d-flex flex-column  gap-2 ">
                <Typography
                  variant="h5"
                  className="career-heading-color fw-bold"
                >
                  Profile Brief:
                </Typography>
                <Typography variant="body1" className="">
                  {data && data?.description["Profile Brief"]}
                </Typography>
              </Box>
              <Box className="d-flex flex-column  gap-2 ">
                <Typography
                  variant="h5"
                  className="career-heading-color fw-bold"
                >
                  Skills Required:
                </Typography>
                <Qualities
                  data={{ul : data?.description["Skills Required"]} || Linguistic}
                />
              </Box>
              <Box className="d-flex flex-column  gap-2 ">
                <Typography
                  variant="h5"
                  className="career-heading-color fw-bold"
                >
                  FEW EMPLOYER TYPES:
                </Typography>
                <Qualities
                  data={{ul : data?.description["FEW EMPLOYER TYPES"] }|| Linguistic}
                />
              </Box>
              <Box className="d-flex flex-column  gap-2 ">
                <Typography
                  variant="h5"
                  className="career-heading-color fw-bold"
                >
                  Salary Expectations:
                </Typography>
                {console.log(data?.description)}
                <Typography variant="body1">
                  {data && data?.description["SALARY EXPECTATIONS"] ||
                        "The average salary for a Singer is INR 15,75,000 per annum in India."
                    }
                </Typography>
              </Box>
            </Grid>
            {/* sec-2 end  */}
            {/* Sec-3  */}
            <Grid item xs={12} className="pt-5">
              <Box>
                <Typography
                  variant="h4"
                  className="career-heading-color fw-bold text-center "
                >
                  ONLINE LEARNING MEDIUM: SINGER
                </Typography>
              </Box>
              <Box>
                <TableView courses = {data?.course || []}></TableView>
              </Box>
            </Grid>
            {/* Sec-3 end */}
          </Grid>{" "}
        </>
      )}
    </Box>
  );
}

function Qualities({ data }) {
  let {ul} = data;
  console.log(ul)
  return (
    <Grid item xs={12}>
      {/* <Typography
          className="shortTextColor"
          sx={{ fontWeight: 500 }}
          variant="h4"
        >
          {heading}
        </Typography> */}
      <Box className={"Qualities_list_container"} sx={{flexDirection : 'column'}} component={'ul'} >
        {ul.map((row) => (          
            <Typography component={"li"} className="fw-normal ">
                {row}
              </Typography>
        ))}
      </Box>
    </Grid>
  );
}

function TableView({courses}) {
  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }


  return (
    <TableContainer component={Paper} className={"pt-4"}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography
                variant="body1"
                className="fs-6 fw-bold  career-heading-color"
              >
                Online Learning Provider
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography
                variant="body1"
                className="fs-6 fw-bold  career-heading-color"
              >
                Course Link
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                variant="body1"
                className="fs-6 fw-bold  career-heading-color"
              >
                About the Course
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                variant="body1"
                className="fs-6 fw-bold  career-heading-color"
              >
                Time Commitment
              </Typography>
            </TableCell>
            <TableCell>
              <Typography
                variant="body1"
                className="fs-6 fw-bold  career-heading-color"
              >
                Course Fees
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {courses.map((row) => (
            <TableRow
              key={row.name}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.provider}
              </TableCell>
              <TableCell align="left"><Link target="_blank" sx={{color : 'blue'}} to={row.link}>Link</Link></TableCell>
              <TableCell align="left">{row.about}</TableCell>
              <TableCell align="left">{row.duration}</TableCell>
              <TableCell align="left">{row.fee}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
