// images
import l1 from "../../assets/other/g1.png";
import l2 from "../../assets/other/g2.png";
import l3 from "../../assets/other/g3.png";
import l4 from "../../assets/other/g4.png";
import l5 from "../../assets/other/g5.png";
import l6 from "../../assets/other/g6.png";
import l7 from "../../assets/other/g7.png";
import l8 from "../../assets/other/g8.png";
import l9 from "../../assets/other/g9.png";
import l10 from "../../assets/other/g10.png";
import l11 from "../../assets/other/g11.png";
import l12 from "../../assets/other/g12.png";
import l13 from "../../assets/other/g13.png";
import l14 from "../../assets/other/g14.png";
import l15 from "../../assets/other/g15.png";
import l16 from "../../assets/other/g16.png";
import l17 from "../../assets/other/g17.png";
import l18 from "../../assets/other/g18.png";

// clp
import img1 from "../../assets/clp/Music Producer.png"
import img2 from "../../assets/clp/Spiritual Leader.png"
import img3 from "../../assets/clp/Actuary.png"
import img4 from "../../assets/clp/AI Engineer.png"
import img5 from "../../assets/clp/Civil or Mechanical or Mechanical Engineer.png"
import img6 from "../../assets/clp/Data Scientist.png"
import img7 from "../../assets/clp/Economist.png"
import img8 from "../../assets/clp/Detective or Lawyer.png"
import img9 from "../../assets/clp/Investment Banking Analyst.png"
import img11 from "../../assets/clp/Lead Financial Manager.png"
import img12 from "../../assets/clp/Network Analyst.png"
import img13 from "../../assets/clp/Portfolio Management Analyst.png"
import img14 from "../../assets/clp/Robotic Scientist.png"
import img15 from "../../assets/clp/Software or Tech Developer.png"
import img16 from "../../assets/clp/Statistician.png"
import img17 from "../../assets/clp/Stock Trader.png"
import img18 from "../../assets/clp/Clergy.png"
import img19 from "../../assets/clp/Consultant.png"
import img20 from "../../assets/clp/Criminologist.png"
import img21 from "../../assets/clp/Entrepreneur.png"
import img22 from "../../assets/clp/Futurist.png"
import img23 from "../../assets/clp/Intelligence Officer.png"
import img24 from "../../assets/clp/Librarian.png"
import img25 from "../../assets/clp/Personal Counselor.png"
import img26 from "../../assets/clp/Program Planner.png"
import img27 from "../../assets/clp/Psychology Teacher.png"
import img28 from "../../assets/clp/Researcher.png"
import img29 from "../../assets/clp/Scientist.png"
import img30 from "../../assets/clp/Spiritual Leader.png"
import img31 from "../../assets/clp/Strategist.png"
import img32 from "../../assets/clp/Therapist.png"
import img33 from "../../assets/clp/Career Counselor.png"
import img34 from "../../assets/clp/Customer Service Specialist.png"
import img35 from "../../assets/clp/Diplomat.png"
import img36 from "../../assets/clp/HR Professionals.png"
import img37 from "../../assets/clp/Marketing Specialist.png"
import img39 from "../../assets/clp/Psychologist.png"
import img41 from "../../assets/clp/Social Media Manager.png"
import img42 from "../../assets/clp/Teacher.png"
import img44 from "../../assets/clp/Communication Manager.png"
import img45 from "../../assets/clp/Social Work Director.png"
import img46 from "../../assets/clp/Sports Coach.png"
import img47 from "../../assets/clp/Chaplain.png"
import img48 from "../../assets/clp/Cosmologist.png"
import img52 from "../../assets/clp/Writer.png"
import img53 from "../../assets/clp/Yoga or Meditation Instructor.png"
import img57 from "../../assets/clp/Astronomer.png"
import img58 from "../../assets/clp/Archaeologist.png"
import img59 from "../../assets/clp/Botanist.png"
import img60 from "../../assets/clp/Botanist.png"
import img61 from "../../assets/clp/Environmental Lawyer.png"
import img63 from "../../assets/clp/Ecologist.png"
import img64 from "../../assets/clp/Zoologist.png"
import img65 from "../../assets/clp/Park Naturalist.png"
import img66 from "../../assets/clp/Doctor.png"
import img67 from "../../assets/clp/Landscape Architect.png"
import img68 from "../../assets/clp/Chef.png"
import img69 from "../../assets/clp/Conservationist.png"
import img70 from "../../assets/clp/Air Quality Specialist.png"
import img71 from "../../assets/clp/Music Composer.png"
import img72 from "../../assets/clp/Radio Jockey.png"
import img73 from "../../assets/clp/Singer.png"
import img74 from "../../assets/clp/Audiologist.png"
import img75 from "../../assets/clp/Booking Agent.png"
import img77 from "../../assets/clp/Choir Director.png"
import img78 from "../../assets/clp/Instrumentalist.png"
import img79 from "../../assets/clp/Music Attorney.png"
import img80 from "../../assets/clp/Journalist.png"
import img82 from "../../assets/clp/Music Teacher.png"
import img84 from "../../assets/clp/Video Game Audio Engineer.png"
import img85 from "../../assets/clp/Advertising Copywriter.png"
import img86 from "../../assets/clp/Copy Editor.png"
import img87 from "../../assets/clp/Critic.png"
import img89 from "../../assets/clp/Media Consultant.png"
import img90 from "../../assets/clp/Newscaster.png"
import img92 from "../../assets/clp/Author.png"
import img98 from "../../assets/clp/Translator.png"
import img99 from "../../assets/clp/Photographer.png"
import img100 from "../../assets/clp/Designer.png"
import img101 from "../../assets/clp/Industrial Designer.png"
import img102 from "../../assets/clp/Interior Designer.png"
import img105 from "../../assets/clp/Digital Software Illustrator.png"
import img106 from "../../assets/clp/Modelling.jpg"
import img107 from "../../assets/clp/Animator or VFX Designer.png"
import img108 from "../../assets/clp/Director.png"
import img109 from "../../assets/clp/Cartographer.png"
import img110 from "../../assets/clp/Curator.png"
import img114 from "../../assets/clp/Construction Engineer.png"
import img118 from "../../assets/clp/Athlete.png"
import img119 from "../../assets/clp/Craftsperson.png"
import img120 from "../../assets/clp/Actor or Actress.png"
import img121 from "../../assets/clp/Dancer.png"
import img122 from "../../assets/clp/Military Personnel.png"
import img123 from "../../assets/clp/Recreational Specialist.png"
import img124 from "../../assets/clp/Theatre Artist.png"
import img125 from "../../assets/clp/Commercial and Defense Aircraft Pilot.png"
import img126 from "../../assets/clp/Company Spokesperson.png"


export const CLPData = {
  "Music Producer": { "img": img1 },
  "Spiritual Guide": { "img": img2 },
  "Actuary": { "img": img3 },
  "AI Engineer": { "img": img4 },
  "Business Intelligence Analyst": { "img": img3 },
  "Civil or Mechanical or Mechanical Engineer": { "img": img5 },
  "Data Scientist": { "img": img6 },
  "Economist": { "img": img7 },
  "Detective or Lawyer": { "img": img8 },
  "Investment Banking Analyst": { "img": img9 },
  "Lead Financial Manager": { "img": img11 },
  "Network Analyst": { "img": img12 },
  "Portfolio Management Analyst": { "img": img13 },
  "Robotic Scientist": { "img": img14 },
  "Software or Tech Developer": { "img": img15 },
  "Statistician": { "img": img16 },
  "Stock Trader": { "img": img17 },
  "Clergy": { "img": img18 },
  "Consultant": { "img": img19 },
  "Criminologist": { "img": img20 },
  "Entrepreneur": { "img": img21 },
  "Futurist": { "img": img22 },
  "Intelligence Officer": { "img": img23 },
  "Librarian": { "img": img24 },
  "Personal Counselor": { "img": img25 },
  "Program Planner": { "img": img26 },
  "Psychology Teacher": { "img": img27 },
  "Researcher": { "img": img28 },
  "Scientist": { "img": img29 },
  "Spiritual Leader": { "img": img30 },
  "Strategist": { "img": img31 },
  "Therapist": { "img": img32 },
  "Career Counselor": { "img": img33 },
  "Customer Service Specialist": { "img": img34 },
  "Diplomat": { "img": img35 },
  "HR Professionals": { "img": img36 },
  "Marketing Specialist": { "img": img37 },
  "Politician": { "img": img35 },
  "Psychologist": { "img": img39 },
  "Public Relations Specialist": { "img": img19 },
  "Social Media Manager": { "img": img41 },
  "Teacher": { "img": img42 },
  "Administrative Manager": { "img": img21 },
  "Communication Manager": { "img": img44 },
  "Social Work Director": { "img": img45 },
  "Sports Coach": { "img": img46 },
  "Chaplain": { "img": img47 },
  "Cosmologist": { "img": img48 },
  "Pastoral Counselor": { "img": img19 },
  "Philosopher": { "img": img33 },
  "Space Scientist": { "img": img48 },
  "Writer": { "img": img52 },
  "Yoga or Meditation Instructor": { "img": img53 },
  "Church Pastor": { "img": img18 },
  "Metaphysicist": { "img": img27 },
  "Psychic": { "img": img22 },
  "Astronomer": { "img": img57 },
  "Archaeologist": { "img": img58 },
  "Botanist": { "img": img59 },
  "Ecologist": { "img": img60 },
  "Environmental Lawyer": { "img": img61 },
  "Environmental Scientist": { "img": img60 },
  "Meteorologist": { "img": img63 },
  "Zoologist": { "img": img64 },
  "Park Naturalist": { "img": img65 },
  "Veterinarian": { "img": img66 },
  "Landscape Architect": { "img": img67 },
  "Chef": { "img": img68 },
  "Conservationist": { "img": img69 },
  "Air Quality Specialist": { "img": img70 },
  "Music Composer": { "img": img71 },
  "Radio Jockey": { "img": img72 },
  "Singer": { "img": img73 },
  "Audiologist": { "img": img74 },
  "Booking Agent": { "img": img75 },
  "A&R Representative": { "img": img61 },
  "Choir Director": { "img": img77 },
  "Instrumentalist": { "img": img78 },
  "Music Attorney": { "img": img79 },
  "Music Journalist": { "img": img80 },
  "Music Publicist": { "img": img37 },
  "Music Teacher": { "img": img82 },
  "Song Writer": { "img": img52 },
  "Video Game Audio Engineer": { "img": img84 },
  "Advertising Copywriter": { "img": img85 },
  "Copy Editor": { "img": img86 },
  "Critic": { "img": img87 },
  "Journalist": { "img": img80 },
  "Media Consultant": { "img": img89 },
  "Newscaster": { "img": img90 },
  "Speech Pathologist": { "img": img44 },
  "Author": { "img": img92 },
  "Communications Specialist": { "img": img44 },
  "Host or Announcer": { "img": img69 },
  "Interpreter": { "img": img87 },
  "Writing Tutor": { "img": img52 },
  "Poet": { "img": img71 },
  "Translator": { "img": img98 },
  "Photographer": { "img": img99 },
  "Fashion Designer": { "img": img100 },
  "Industrial Designer": { "img": img101 },
  "Interior Designer": { "img": img102 },
  "Mechanical or Architectural or Civil Drafter": { "img": img5 },
  "Advertising Head": { "img": img5 },
  "Digital Software Illustrator": { "img": img105 },
  "3D Modelling & Simulation Artist": { "img": img106 },
  "Animator or VFX Designer": { "img": img107 },
  "Artist or Painter or Art Director": { "img": img108 },
  "Cartographer": { "img": img109 },
  "Curator": { "img": img110 },
  "Geologist": { "img": img109 },
  "Graphic Designer": { "img": img90 },
  "Astronaut": { "img": img57 },
  "Construction Engineer": { "img": img114 },
  "Doctor": { "img": img66 },
  "Physical Therapist": { "img": img32 },
  "Police Officer": { "img": img23 },
  "Athlete": { "img": img118 },
  "Craftsperson": { "img": img119 },
  "Actor or Actress": { "img": img120 },
  "Dancer": { "img": img121 },
  "Military Personnel": { "img": img122 },
  "Recreational Specialist": { "img": img123 },
  "Theatre Artist": { "img": img124 },
  "Commercial and Defense Aircraft Pilot": { "img": img125 },
  "Company Spokesperson": { "img": img126 }
}

export const cardData = {
  cards: {
    Interpersonal: {
      img: l1,
      title: "Interpersonal",
    },
    Linguistic: {
      img: l2,
      title: "Linguistic",
    },
    Existential: {
      img: l3,
      title: "Existential",
    },

    "Intrapersonal": {
      img: l4,
      title: "Intrapersonal",
    },

    "Bodily-Kinesthetic": {
      img: l6,
      title: "Bodily Kinesthetic",
    },

    Naturalistic: {
      img: l5,
      title: "Naturalistic",
    },

    "Visual-Spatial": {
      img: l7,
      title: "Visual-Spatial",
    },

    Musical: {
      img: l8,
      title: "Musical",
    },

    "Logical-Mathematical": {
      img: l9,
      title: "Logical-Mathematical",
    },
    "Conventional": {
      img: l10,
      title: "Conventional",
    },
    "Enterprising": {
      img: l11,
      title: "Enterprising",
    },
    "Social": {
      img: l12,
      title: "Social",
    },
    "Journalist": {
      img: l13,
      title: "Enterprising",
    },
    "Singer": {
      img: l15,
      title: "Singer",
    },
    "Realistic": {
      img: l18,
      title: "Realistic",
    },
    "Investigative": {
      img: l17,
      title: "Investigative",
    },
    "Artistic": {
      img: l16,
      title: "Artistic",
    },
  },
};

export const Intelligence_Point = {
  "Intrapersonal": {
    "heading": "Intrapersonal Intelligence",
    "ul": [
      [
        "Therapist",
        "Strategist",
        "Spiritual Leader",
        "Scientist"
      ],
      [
        "Researcher",
        "Psychology Teacher",
        "Program Planner",
        "Personal Counselor"
      ],
      [
        "Librarian",
        "Intelligence Officer",
        "Futurist",
        "Entrepeneur"
      ],
      [
        "Criminologist",
        "Consultant",
        "Clergy"
      ]
    ]
  },
  "Musical": {
    "heading": "Musical Intelligence",
    "ul": [
      [
        "Video Game Audio Engineer",
        "Song Writer",
        "Music Teacher",
        "Music Publicist"
      ],
      [
        "Music Journalist",
        "Music Attorney",
        "Instrumentalist",
        "Choir Director"
      ],
      [
        "A&R Representative",
        "Booking Agent",
        "Audiologist",
        "Singer"
      ],
      [
        "Radio Jockey",
        "Music Composer",
        "Music Producer"
      ]
    ]
  },
  "Bodily-Kinesthetic": {
    "heading": "Bodily-Kinesthetic Intelligence",
    "ul": [
      [
        "Sports Coach",
        "Commercial and Defense Aircraft Pilot",
        "Theatre Artist",
        "Recreational Specialist"
      ],
      [
        "Military Personnel",
        "Dancer",
        "Actor or Actress",
        "Craftsperson"
      ],
      [
        "Athlete",
        "Police Officer",
        "Physical Therapist",
        "Doctor"
      ],
      [
        "Construction Engineer",
        "Astronaut",
        "Yoga or Meditation Instructor"
      ]
    ]
  },
  "Logical-Mathematical": {
    "heading": "Logical-Mathematical Intelligence",
    "ul": [
      [
        "Stock Trader",
        "Statistician",
        "Software or Tech Developer",
        "Robotic Scientist"
      ],
      [
        "Portfolio Management Analyst",
        "Network Analyst",
        "Lead Financial Manager",
        "Investment Banking Analyst"
      ],
      [
        "Detective or Lawyer",
        "ECONOMIST",
        "Data Scientist",
        "Civil or Mechanical or Mechanical Engineer"
      ],
      [
        "Business Intelligence Analyst",
        "AI Engineer",
        "Actuary"
      ]
    ]
  },
  "Linguistic": {
    "heading": "Linguistic Intelligence",
    "ul": [
      [
        "Company Spokesperson",
        "Translator",
        "Poet",
        "Writing Tutor"
      ],
      [
        "Interpreter",
        "Host or Announcer",
        "Communications Specialist",
        "Author"
      ],
      [
        "Speech Pathologist",
        "Newscaster",
        "Media Consultant",
        "Journalist"
      ],
      [
        "Critic",
        "Copy Editor",
        "Advertising Copywriter"
      ]
    ]
  },
  "Visual-Spatial": {
    "heading": "Visual-Spatial Intelligence",
    "ul": [
      [
        "Archaeologist",
        "Graphic Designer",
        "Geologist",
        "Curator"
      ],
      [
        "Cartographer",
        "Artist or Painter or Art Director",
        "Animator or VFX Designer",
        "3D Modelling & Simulation Artist"
      ],
      [
        "Digital Software Illustrator",
        "Advertising Head",
        "Mechanical or Architectural or Civil Drafter",
        "Interior Designer"
      ],
      [
        "Industrial Designer",
        "Fashion Designer",
        "Photographer"
      ]
    ]
  },
  "Existential": {
    "heading": "Existential Intelligence",
    "ul": [
      [
        "Career Counselor",
        "Scientist",
        "Psychologist",
        "Astronomer"
      ],
      [
        "Psychic",
        "Metaphysicist",
        "Church Pastor",
        "Yoga or Meditation Instructor"
      ],
      [
        "Writer",
        "Space Scientist",
        "Philosopher",
        "Pastoral Counselor"
      ],
      [
        "Cosmologist",
        "Chaplin",
        "Spiritual Guide"
      ]
    ]
  },
  "Naturalistic": {
    "heading": "Naturalistic Intelligence",
    "ul": [
      [
        "Photographer",
        "Air Quality Specialist",
        "Conservationist",
        "Chef"
      ],
      [
        "Landscape Architect",
        "Veterinarian",
        "Park Naturalist",
        "Zoologist"
      ],
      [
        "Meteorologist",
        "Environmental Scientist",
        "Environmental Lawyer",
        "Ecologist"
      ],
      [
        "Astronomer",
        "Botanist",
        "Archaeologist"
      ]
    ]
  },
  "Interpersonal": {
    "heading": "Interpersonal Intelligence",
    "ul": [
      [
        "Detective or Lawyer",
        "Sports Coach",
        "Social Work Director",
        "Communication Manager"
      ],
      [
        "Administrative Manager",
        "Teacher",
        "Social Media Manager",
        "Public Relations Specialist"
      ],
      [
        "Psychologist",
        "Politician",
        "Marketing Specialist",
        "HR Professionals"
      ],
      [
        "Diplomat",
        "Customer Service Specialist",
        "Career Counselor"
      ]
    ]
  }
}


// export const Musical = {
//   heading: "Musical Intelligence",
//   ul: [
//     ["Music", "Producer", "Music", "Composer"],
//     ["Radio Jockey", "Singer", "Audiologist", "Booking Agent"],
//     ["A&R Representative", "Choir Director", "Instrumentalist", "Music"],
//   ],
// };
// export const Linguistic = {
//   heading: "Linguistic Intelligence",
//   ul: [
//     ["Advertising", "Copywriter", "Copy Editor", "Critic"],
//     ["Journalist", "Media", "Consultant", "Newscaster"],
//     ["Speech Pathologist", "Author", "Communications", "Specialist"],
//   ],
// };
// export const Intelligence = {
//   heading: "Bodily-Kinesthetic Intelligence",
//   ul: [
//     ["Music Producer", "Music", "Composer", "Radio Jockey"],
//     ["Music Producer", "Music", "Composer", "Radio Jockey"],
//     ["Music Producer", "Music", "Composer", "Radio Jockey"],
//   ],
// };
