import { Box, Button, Fade, Grid, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import { useSpring, animated } from "@react-spring/web";

//data
import { INTELLIGENCES } from "./data";
// images
import p9 from "../../assets/MIIT/p9 1.png";
import p8 from "../../assets/MIIT/p8 1.png";
import p7 from "../../assets/MIIT/p7 1.png";
import p6 from "../../assets/MIIT/p6 1.png";
import p5 from "../../assets/MIIT/p5 1.png";
import p4 from "../../assets/MIIT/p4 1.png";
import p3 from "../../assets/MIIT/p3 1.png";
import p2 from "../../assets/MIIT/p2 1.png";
import p1 from "../../assets/MIIT/p1 1.png";

//css
import "../../assets/css/MIIT.css";
import { Link, useNavigate } from "react-router-dom";
import { MIIT_base_URI } from "../../config";
import { setMIITSession } from "../services/services";
import { useDispatch, useSelector } from "react-redux";
import { tracker } from "../../App";
import useTrackTime from "../../hook/useTrackTime";
import { setAlert } from "../../redux/action/action";

export default function MIIT({ history }) {
  const timeSpan = useTrackTime();
  const [content, setContent] = useState({
    ...INTELLIGENCES["Logical-Mathematical"],
  });
  const [open, setModal] = useState(false);
  const { auth } = useSelector((state) => state);
  useEffect(() => {
    const isMobile = window.matchMedia("(max-width: 600px)").matches;
    if (isMobile) {
      document.body.style.zoom = "50%";
    }

    return ()=>{
      document.body.style.zoom = "100%";
    }
  }, []);


  function onHover(tab) {
    setContent({ ...INTELLIGENCES[tab] });
  }
  return (
    <Grid
      container
      className="container-fluid d-flex flex-column MIIT-container"
    >
      {/* <!-- heading --> */}
      <Grid item xs={12}>
        <Typography variant="h3" className="heading-text heading-color">
          Multiple Intelligence, Interest & Talent
        </Typography>
      </Grid>
      {/* <!-- // graphic and text section --> */}
      <Grid
        item
        xs={12}
        className="d-flex flex-column flex-wrap  w-100 align-items-center flex-md-row "
      >
        {/* <!-- // image  --> */}
        <Box className="graphic-container" sx={{textAlign : 'center'}}> 
        <AnimatedCircles content = {content} onHover={onHover}></AnimatedCircles>
        <Typography  variant="caption"><i style={{color:"black"}}> Hover or click on intelligence icons. </i></Typography>
        </Box>
        {/* <!-- text  --> */}
        <Box className="d-flex flex-column text-container gap-4 align-items-baseline">
          <Typography variant="h4" className="sub-heading heading-color">
            {content.heading}
          </Typography>
          <Typography variant="body1" className="pra-text">
            {content.content}
          </Typography>
          <Button
            to={`${MIIT_base_URI}/MITest/pay`}
            onClick={() => {
              setModal(!open);
              tracker("Button Click", "MITest Button Click");
            }}
            className="btn btn-bg text-white  border px-4 border-0"
          >
            Take Test
          </Button>
        </Box>
      </Grid>
      {/* <!-- // graphic and text section end --> */}
      <Grid
        item
        xs={12}
        className="d-flex flex-1 mt-5  flex-column gap-4 align-items-baseline"
      >
        <Typography
          variant="h4"
          sx={{ fontWeight: 600 }}
          className="sub-heading heading-color"
        >
          What is M.I.I.T?
        </Typography>
        <Typography variant="body1" className="pra-text">
          This refers to the ability to use, write and speak words effectively.
          Individuals with high Linguistic Intelligence have developed auditory
          skills and often think in words. They like reading, writing stories or
          poetry etc. This intelligence type is most prevalent in successful
          writers, speakers, poets, journalists etc. This refers to the ability
          to use, write and speak words effectively. Individuals with high
          Linguistic Intelligence have developed auditory skills and often think
          in words. They like reading, writing stories or poetry etc. This
          intelligence type is most prevalent in successful writers, speakers,
          poets, journalists etc.
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        className="d-flex mt-5  flex-column gap-4 align-items-baseline"
      >
        <Typography
          variant="h4"
          sx={{ fontWeight: 600 }}
          className="sub-heading heading-color"
        >
          Benifits of M.I.I.T?
        </Typography>
        <Typography variant="body1" className="pra-text">
          This refers to the ability to use, write and speak words effectively.
          Individuals with high Linguistic Intelligence have developed auditory
          skills and often think in words. They like reading, writing stories or
          poetry etc. This intelligence type is most prevalent in successful
          writers, speakers, poets, journalists etc. This refers to the ability
          to use, write and speak words effectively. Individuals with high
          Linguistic Intelligence have developed auditory skills and often think
          in words. They like reading, writing stories or poetry etc. This
          intelligence type is most prevalent in successful writers, speakers,
          poets, journalists etc.
        </Typography>
        <Box className={"w-100 d-flex justify-content-center "}>
          <Button
            to={`${MIIT_base_URI}/MITest/pay`}
            onClick={() => {
              setModal(!open);
              tracker("Button Click", "MITest Interest Button Click");
            }}
            className="btn btn-bg text-white  border px-4 border-0"
          >
            Take Test
          </Button>
        </Box>
      </Grid>
      <ModalBox auth={auth} history={history} open={open} setModal={setModal} />
    </Grid>
  );
}

function ModalBox({ auth, open, setModal, history }) {
  let {mi_intelligence} = useSelector(state => state.auth);
  let dispatch = useDispatch();
  function handleClose() {
    setModal(!open);
  }

  async function handleMIIT() {
    tracker("Button Click", "MITest Interest Button Click");
    if (!auth.isAuth) return history("/login");
    // creating the session to skip logins
    // let res = await setMIITSession({email : auth.email});
    // console.log(res)
    // if(res.status === 200)
    // {    // redirect to MIIT site
    if(!mi_intelligence?.length)
    {
      window.open(
        `${MIIT_base_URI}/MITest/pay?email_address=${auth.email}`,
        "_blank"
      );
    }
    else {
      dispatch(setAlert({
        variant : "info",
        message : "Look like you have already given the test. Please login with another account to take the test.",
        open : true
      }))
      history("profile")
    }
    // }
    // close modal
    setModal(!open);
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box className={"modal-box"}>
          <Box>
            <Typography variant="h5" className="heading-color ">
              How To Take Test
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1">
              Multiple Intelligence Test - Instructions This is a Test (M.I.I.T.
              Screening) to judge different type of Intelligence(s) that you are
              born with and/or have inherited naturally. There is no right or
              wrong answers, you have to answer each question with the answer
              that first comes to your mind and is true to your personality and
              behavior. The results will show you the intelligence types that
              are most prominent in you. According to your major intelligence
              type(s), Edubuk Team will create your "Learner's Profile" and will
              help you which type of job(s) are more suitable for you in terms
              of being successful in your career. The Test is timed. Each
              question will be shown for 15 seconds and you have to answer it
              within that time frame. You will be shown a timer at the top right
            </Typography>
          </Box>{" "}
          <Box className="align-self-end ">
            <Button
              to={`${MIIT_base_URI}/MITest/pay`}
              onClick={() => handleMIIT()}
              className="btn btn-bg text-white   border px-4 border-0"
            >
              Proceed To Test
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}


const AnimatedCircles = ({onHover, content}) => {
  const containerRef = useRef(null);
  const svgContainerRef = useRef(null);
  const imgArr = [
    p9,p8,p7,p6,p5,p4,p3,p2,p1
  ]
  const tabArr = [
    "Linguistic","Naturalistic","Logical-Mathematical","Interpersonal","Visual-Spatial","Musical","Bodily-Kinesthetic","Intrapersonal","Existential"
  ]
  useEffect(() => {
    const containerRect = containerRef.current.getBoundingClientRect();
    const svgContainer = svgContainerRef.current;

    const bigCirclePos = {
      x: containerRect.width / 2,
      y: containerRect.height / 2
    };

    const smallCircles = Array.from(containerRef.current.querySelectorAll('.small-circle'));
    const radius = 150;

    smallCircles.forEach((circle, index) => {
      const angle = (index / smallCircles.length) * (2 * Math.PI);
      const x = bigCirclePos.x + radius * Math.cos(angle) - circle.offsetWidth / 2;
      const y = bigCirclePos.y + radius * Math.sin(angle) - circle.offsetHeight / 2;
      circle.style.left = `${x}px`;
      circle.style.top = `${y}px`;
    });

    const paths = [];
    smallCircles.forEach((circle, index) => {
      const rect = circle.getBoundingClientRect();
      const pos = {
        x: rect.left + rect.width / 2 - containerRect.left,
        y: rect.top + rect.height / 2 - containerRect.top
      };
      const controlPointX = (pos.x + bigCirclePos.x) / 2;
      const controlPointY = (pos.y + bigCirclePos.y) / 2 - 50;

      const path = document.createElementNS("http://www.w3.org/2000/svg", "path");
      path.setAttribute('d', `M ${pos.x} ${pos.y} Q ${controlPointX} ${controlPointY} ${bigCirclePos.x} ${bigCirclePos.y}`);
      path.classList.add("circle-path")
      svgContainer.appendChild(path);
      paths.push(path);

      const beam = document.createElement('div');
      beam.classList.add('beam');
      containerRef.current.appendChild(beam);

      const pathLength = path.getTotalLength();
      const duration = pathLength / 100 * 1000;

      beam.style.offsetPath = `path("${path.getAttribute('d')}")`;
      beam.style.offsetDistance = '0%';

      beam.animate([
        { offsetDistance: '0%' },
        { offsetDistance: '100%' }
      ], {
        duration: duration,
        easing: 'linear',
        iterations: Infinity,
        delay: index * 500
      });
    });
  }, []);

  return (
    <div className="container" ref={containerRef}>
      <div className="big-circle" id="bigCircle">
        <Typography variant="h4">MIIT</Typography>
      </div>
      {[...Array(9).keys()].map(i => (
        <div onMouseEnter={() => onHover(tabArr[i])} key={i} className={`small-circle ${INTELLIGENCES[tabArr[i]].heading === content.heading ? "enlarged" : "normal" } ${i < 3 ? "box-1" : i < 6 ? "box-2" : "box-3"}`} id={`circle${i + 1}`}>
          <img src={imgArr[i]} alt="point_img" className="img-fluid scaling-image" />
        </div>
      ))}
      <svg className="circle-svg" id="svgContainer" ref={svgContainerRef}></svg>
    </div>
  );
}

