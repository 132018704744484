import { Box, Button, Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import vector from "../../assets/other/vector-card.png";
import { cardData, Intelligence_Point } from "./data";
import { Helmet } from "react-helmet";
import useTrackTime from "../../hook/useTrackTime";
import { Link } from "react-router-dom";
import { MIIT_base_URI } from "../../config";
import { tracker } from "../../App";

export function CongressInterest() {
  const timeSpan = useTrackTime();

  let { auth } = useSelector((state) => state);
  return (
    <Box sx={{ padding: "0% 5% 0% 5%" }} mb={5}>
      {/* helmet tag  */}
      <Helmet>
        <title>ClassBazaar || Congratulations</title>
        <meta
          name="description"
          content="Profile section is here, let's check your progress together."
        />
      </Helmet>
      {/* helmet tag ends  */}
      {/* Main container */}
      <Grid container mt={3} className="mainContainer_Congress" p={1}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant="h4"
              sx={{ fontWeight: "500" }}
              className="userTextColor"
            >
              PERSONALIZED INTEREST TRIGON
            </Typography>
            <Box component={"ol"} className="PERSONALIZED-list  mt-3">
              <Typography variant="body1" component={"li"}>
                The questions in the MIIT Screening Tests are designed so that
                when you respond to a question, your preference is clearly
                stated and matched with the relevant intelligence type.
              </Typography>
              <Typography variant="body1" component={"li"}>
                Based on your unique set of responses to each question, our
                analysis shows that you have 3 dominant types of intelligences
                as depicted in the trigon presented above.
              </Typography>
              <Typography variant="body1" component={"li"}>
                Based on your dominant intelligence types we have mapped a few
                relevant career paths for you in the next section of this
                report.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography
              className="shortTextColor"
              sx={{ fontWeight: 500 }}
              variant="h4"
            >
              Your Interest Trigon
            </Typography>
            <Grid container pt={5}>
              {auth.mi_interest.length !== 0 ? (
                <Grid container className="userActivityContainer">
                  <Cards item={auth.mi_interest} />
                </Grid>
              ) : (
                <Grid
                  container
                  className="d-flex justify-content-center flex-column align-items-center gap-4"
                >
                  <Typography
                    sx={{ color: "rgba(131, 131, 131, 0.686)" }}
                    variant="h5"
                  >{`Hey, ${auth.name} take our test and discover your true potential.`}</Typography>
                  <Button
                    className="custom-btn"
                    component={Link}
                    to={`${MIIT_base_URI}/MITest/pay`}
                    onClick={() =>
                      tracker("Button Click", "MITest Button Click")
                    }
                    variant="contained"
                  >
                    Take Test
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
          {/* <Grid item xs={12} className="mt-3">
          <Typography
            className="shortTextColor"
            sx={{ fontWeight: 500 }}
            variant="h4"
          >
            Your Career Interest Trigon
          </Typography>
          <Grid container pt={5}>
          {auth.mi_interest.length !== 0 ? (
              <Grid container className="userActivityContainer">
                <Cards
                  item={auth.mi_interest}
                />
              </Grid>
            ): <Grid container className="d-flex justify-content-center flex-column align-items-center gap-4">
              <Typography sx={{color: "rgba(131, 131, 131, 0.686)"}}  variant="h5">{`Hey, ${auth.name} take our test and divcover your ture potential.`}</Typography>
              <Button className="custom-btn" variant='contained'>Take Test</Button>
              </Grid>}
          </Grid>
        </Grid> */}
        </Grid>
      </Grid>
    </Box>
  );
}

function Cards({ item, heading }) {
  return (
    <Grid item xs={12}>
      {" "}
      <Grid container sx={{ gap: 4 }}>
        {heading && (
          <Grid item xs={12} className="cardHead">
            <Typography variant="h5">{heading}</Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <Grid container className="profileCardContainer">
            {/* // itrate */}
            {item.map((row) => (
              <Grid className="profileCard" item xs={12} md={3}>
                <Box className="profileCardVector">
                  <img src={vector} alt="vector"></img>
                </Box>
                <Box className="profileCardImg">
                  <img
                    sx={{ width: "100%" }}
                    src={cardData?.cards[row]?.img}
                    alt="test"
                  />
                </Box>
                <Box className="profileCardName">
                  <Typography variant="h6">
                    {cardData.cards[row].title}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
